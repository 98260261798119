
.accordion-body
  background: $light
  color: black
.accordion-button
  color: $light
  background: black
  margin-bottom: 0.1rem
  font-weight: lighter
.accordion-button:focus
  box-shadow: none
.accordion-button:not(.collapsed)
  color: black
  background: $light
button.accordion-button:not(.collapsed)::after
  background-image: url('../../assets/images/minus.svg')
button.accordion-button::after
  background-image: url('../../assets/images/plus.svg')
