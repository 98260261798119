/* Overwriting Bootstrap Defaults / Default Elements */

.h1, h1, .h2, h2, .h3, h3
  font-family: SamsungSharpSans-Bold

.h4, h4, .h5, h5, .h6, h6
  font-family: SamsungSharpSans-Medium

.p, p, .ul, ul, .li, li, table, .table
  font-family: SamsungOne-450

.btn-outline-primary, .btn-primary, .btn-secondary
  font-family: SamsungOne-700
  font-weight: bold

.btn-outline-primary
  background-color: transparent
  font-size: 14px
  &:hover
    background-color: $white
    border-color: $white
    color: $black
  &:focus
    background-color: transparent
    border-color: $white
    color: $white

.btn-primary
  font-size: 14px
  &:hover
    opacity: 0.8

.no-hover
  &:hover
    box-shadow: none
    transform: none
    transition: none
