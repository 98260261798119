//mixins
@import "styles/mixins/fontface"

//fonts
@include fontFace('SamsungOne-200', '/assets/fonts/SamsungOne/SamsungOne-200')
@include fontFace('SamsungOne-300', '/assets/fonts/SamsungOne/SamsungOne-300')
@include fontFace('SamsungOne-400', '/assets/fonts/SamsungOne/SamsungOne-400')
@include fontFace('SamsungOne-450', '/assets/fonts/SamsungOne/SamsungOne-450')
@include fontFace('SamsungOne-500', '/assets/fonts/SamsungOne/SamsungOne-500')
@include fontFace('SamsungOne-600', '/assets/fonts/SamsungOne/SamsungOne-600')
@include fontFace('SamsungOne-700', '/assets/fonts/SamsungOne/SamsungOne-700')
@include fontFace('SamsungOne-800', '/assets/fonts/SamsungOne/SamsungOne-800')
@include fontFace('SamsungSharpSans-Regular', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Regular')
@include fontFace('SamsungSharpSans-Medium', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Medium')
@include fontFace('SamsungSharpSans-Bold', '/assets/fonts/SamsungSharpSans/SamsungSharpSans-Bold')

// theme
@import styles/prebootstrapvariables
@import ../node_modules/bootstrap/scss/functions
@import styles/bootstrapvariables
@import styles/variables

// vendor modules
@import styles/mixins/animate
@import '../node_modules/react-multi-carousel/lib/styles.css'
@import "../node_modules/bootstrap/scss/bootstrap"
@import "styles/bootstrapoverwrites"

// stylesheets containers
@import styles/containers/Root
@import styles/containers/App
@import styles/containers/Landingpage
@import styles/containers/Dashboard
@import styles/containers/FourOFour
@import styles/containers/Navbar

//stylesheets components
// @import ../node_modules/@bkry/bowline-components/Navbar/Navbar
@import ../node_modules/@bkry/bowline-components/BowlineWallet/BowlineWallet
@import ../node_modules/@bkry/bowline-components/ConnectWalletButton/AuthConnectButton
@import ../node_modules/@bkry/bowline-components/ConnectWallet/ConnectWallet
@import ../node_modules/@bkry/bowline-components/Roadmap/Roadmap
@import "components/Faq/Faq-component"
@import "components/Footer/Footer-component"
@import "components/LedgerSection/LedgerSection"
@import "components/Minting/MintPassCard/MintPassCard"
@import "components/ArtMintTabs/ArtMintTabs"
@import "components/Countdown/Countdown"

body
  margin: 0
  padding: 0
  overflow: scroll !important
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.cursor-pointer
  cursor: pointer

.bigger-margin-top
  margin-top: 8rem
  @include media-breakpoint-down(md)
    margin-top: 3rem

.my-md-0
  @include media-breakpoint-down(md)
    margin-top: 0
    margin-bottom: 0
.pt-md-0
  @include media-breakpoint-down(md)
    padding-top: 0
    padding-bottom: 0

.pointer-url
  cursor: pointer
  text-decoration: underline

.bigger-margin-bottom
  margin-bottom: 8rem
  @include media-breakpoint-down(md)
    margin-bottom: 1rem

.mobile-push-top
  margin-top: -4rem

.text-lg-prewrap
  @include media-breakpoint-up(lg)
    white-space: pre-wrap

.about-background
  background-position: left 34%
  background-size: contain
  background-repeat: no-repeat
  @include media-breakpoint-up(lg)
    background-position: left top

.navigationbar--sticky
  @include media-breakpoint-down(md)
    position: relative !important

.modal .btn-close
  filter: invert(1) grayscale(100%) brightness(50%)

#PartnerDeals
  .card
    background-color: $gray-dark !important
    color: $white !important
    .card-body
      color: $white !important
      .btn
        border-radius: 50rem !important
        max-width: 420px !important

.unlock-token-modal
  .modal-content
    background-color: $gray-dark !important
    .modal-header
      border-bottom: none !important
      background-color: $gray-dark !important
    .modal-body
      background-color: $gray-dark !important
    .modal-footer
      border-top: none !important
      background-color: $gray-dark !important
      .btn
        font-family: SamsungOne-450 !important
        border-radius: 50rem !important
        min-width: 93px
        font-weight: bold
      .btn-outline-danger
        color: $white !important
        border-color: $white !important
        &:hover
          background: $white !important
          color: $black !important
          font-weight: bold
      .btn-success
        color: $black !important
        background: $white !important
        border-color: $white !important
        &:hover
          opacity: .8
