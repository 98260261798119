#content
  padding-top: calc(#{$navbar-height})
  min-height: calc(100vh - (#{$navbar-height} + #{$footer-height}))
  @include media-breakpoint-down(md)
    padding-top: 0px

.preview-on-hover
  display: flex
  height: 100%
  width: 100%
  flex-direction: column
  justify-content: flex-end
  text-decoration: none
  transition: background-color 0.5s
  p
    transition: opacity 0.5s
    opacity: 0
.preview-on-hover:hover
  background: linear-gradient(to top, black, rgba(0, 0, 0, 0) 50%)
  p
    opacity: 100% !important
