$navbar-height: 80px
.navigationbar
  .dropdown-menu
    --bs-dropdown-bg: #111111
  height: $navbar-height
  z-index: 999
  position: fixed
  width: 100%
  transition: background-color 500ms linear
  .navbar
    height: 100%
    .nav-link
      color: $white
      border-radius: 16px
      font-size: 14px
      line-height: 32px
      padding: 0 8px
      font-weight: bold
      &:hover
        background-color: $white
        color: $black

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link
  color: var(--bs-body-color)

.navbar-collapse
  @include media-breakpoint-down(sm)
    margin: 0.5rem -1rem
    background-color: var(--bs-body-bg)
    padding: 1rem
    .navbar-nav
      .nav-link
        color: var(--bs-body-color)

.blinker
  animation: blink-animation .75s steps(5, start) infinite
  -webkit-animation: blink-animation .75s steps(5, start) infinite

@keyframes blink-animation
  to
    visibility: hidden
@-webkit-keyframes blink-animation
  to
    visibility: hidden