.countdown
  ul
    padding: 0
    margin: 0
  li
    display: inline-block
    font-size: 1rem
    list-style-type: none
    text-transform: uppercase
    &:not(:last-of-type)
      margin-right: 2rem

    .value
      display: block
      font-size: 2.5rem

  .countdownended
    font-size: 2.5rem